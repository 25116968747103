export default {
    navs: {
        client: "Painel do Cliente"
    },
    page_change: {
        loading: "Carregando página"
    },
    index: {
        rede_section: {
            title: "Estrutura de Rede",
            subTitle: "Mapa de algumas localidades da nossa mitigação de 248 Tbps e pontos de conexão da nossa rede, entregando a nossos usuários uma conexão incomparável e mitigação exemplar.",
            help: {
                "datacenter": {
                    title: "",
                    description: "Datacenter",
                },
                "pop": {
                    title: "",
                    description: "Ponto de conexão/Troca de tráfego",
                },
                "msr": {
                    title: "",
                    description: "Centro de limpeza de tráfego (Anti-DDoS)",
                }
            }
        },
        differential_section: {
            title: "Nossos diferenciais"
        },
        clients_section: {
            title: "Nossos Clientes"
        }
    },
    infrastructure: {
        tabs: {
            datacenter: "Data Center",
            colocation: "Colocation",
            network: "Rede",
            anti_ddos: "Anti-DDoS"
        },
        datacenter_section: {
            title: "Data Center",
            subTitle: "Estrutura localizada em Cotia- SP, contando com diversas modernidades e certificações, a fim de entregar uma ótima experiência a nossos clientes.",
            resources :{
                team: "Equipe de segurança especializada e monitorando 24/7/365",
                redundancy: "Redundância total de operação (Energia, Refrigeração, Fibras e Equipamentos).",
                sla: "SLA de 100% sobre energia, mantidos com UPS e geradores com capacidade de garantir o funcionamento por mais de 24 horas sem energia.",
                environment: "Ambiente controlado a 25ºC e 30% de umidade.",
                anti_fire: "Sistema de incêndio com gás HFC-125, garantindo a contenção de qualquer incêndio sem danificar os equipamentos.",
                certifications: "Certificações ISO/IEC27017 e ISO/IEC 27001."
            }
        },
        network_section: {
            title: "Rede",
            subTitle: "Tenha o melhor ping e estabilidade do Brasil, garantindo toda a segurança e estabilidade, além de uma rede protegida.",
            resources :{
                ix_br: "Conexão com o principal ponto de troca de tráfego brasileiro (IX.br São Paulo)",
                tier_1: "Conexão com o principal Carrier Tier 1 do mercado nacional e internacional.",
                ddos_alwaysOn: "Sistema Anti-DDoS Always On, garantindo a máxima proteção para ataques volumétricos, sem a utilização de blackhole/null route ou troca de rota.",
                monitor: "Monitoramento constante do tráfego.",
                manrs: "Certificações de boas práticas para a segurança da Internet.",
                devices: "Equipamentos das melhores marcas do mercado.",
                connections: "Conexão com outros ponto de troca de tráfego nacionais, além de acordos de peering com provedores Clouds e CDNs."
            }
        },
        anti_ddos_section: {
            title: "Anti-DDoS",
            subTitle: "Sem alterações de rota, sem aumento de ping ou atraso para mitigar seu ataque, com 248 Tbps de capacidade.",
            map: "Centros de Limpeza de Tráfego",
            resources: {
                always_on: "Mitigação sempre ativa, garantindo o melhor tempo de resposta contra qualquer ameaça.",
                team: "Monitoramento e equipe dedicada a solução de qualquer situação.",
                mitigation_mode: "Mitigação dos ataques próximo a origem, garantindo assim, que não haja esgotamento de recursos no destino.",
                servers: "Todos os serviços no Brasil incluem a mitigação DDoS.",
                vendor: "Solução multi vendor, garantindo sempre a melhor abordagem para a limpeza do tráfego.",
                null_route: "Não utilizamos técnicas de null route/blackhole ou mudança de rota.",
                ddos_pro: "Em caso de ataques zero-day, ataques de aplicação, entre outros níveis mais complexos, possuímos a solução DDoS Pro, garantindo com sua inteligência artificial resposta imediata para qualquer tipo de ameaça, seja um ataque nunca antes visto, como algo impossível de bloquear por meios comuns.",
                last_year_block: "Ataques bloqueados em 2022",
                last_year_block_regions: "Por região",
                last_year_block_types: "Por tipo",
                biggest_last_year_block: "Os Maiores Ataques",
                total_last_year_block: "Total mitigado",
                major_attack_mitigated: "Maior ataque já mitigado: 2.7Tb/s (pico inicial)"
            },
            ddos_pro: {
                title: "DDoS Pro",
                subTitle: "Entre em contato para saber como contratar.",
                resources: {
                    ai: "Inteligência artificial analisando todo o tráfego constantemente para identificar toda possibilidade de DDoS logo no início com menos de 100ms para iniciar uma ação.",
                    learning: "Análise de todo o fluxo de dados para identificar ataques complexos e indetectáveis.",
                    painel: "Painel de acesso ao cliente com report de todo incidente e a todos os dados.",
                    port_learning: "Análise de portas isoladamente, gerando mitigações específicas para todo o tipo de tráfego diferente que cada serviço utiliza.",
                    zero_trust: "Políticas de Confiança Zero (Zero Trust)",
                    threatstop: "Atualizações diárias com sistema de análise global de anomalias e vetores de ataques."
                }
            }
        }
    },
    colocation: {
        title: "Colocation",
        subTitle: "Estrutura montada com toda dedicação, qualidade e comprometimento. Estamos fornecendo a estrutura que você montaria para sua empresa, podendo ter certeza que seu servidor estará em boas mãos.",
        resources :{
            redundancy: "Redundância total de equipamentos para garantir que toda a sua operação funcione 24/7/365 sem falhas.",
            diferencial: "Datacenter pensado para oferecer tudo que há de melhor a nossos clientes, estrutura montada pensando em zero comprometimentos, garanta a melhor estabilidade e segurança.",
            types: "Possibilidade de colocation variando de 1U até Cage Rack."
        },
        contact: "Entre em contato para solicitar uma cotação."
    },
    footer: {
        certifications: {
            title: "Certificações"
        },
        links: {
            title: "Links Úteis"
        },
        social: {
            title: "Social"
        },
        contato: {
            title: "Contato"
        },
        status: {
            ok: "Todos os sistemas estão operacionais.",
            warning: "Interrupção parcial nos sistemas.",
            danger: "Grande interrupção nos sistemas.",
            maintenance: "Uma manutenção está em andamento.",
            scheduled_maintenance: "Existe uma manutenção agendada para "
        },
        copy: "Todos os direitos reservados"
    },
    products: {
        new: "Novo",
        pre_venda: "Pré-Venda",
        em_breve: "Em breve",
        unlimit: "Ilimitado",
        none: "Nenhum produto encontrado",
        tag_search: {
            category: "Categoria",
            memory: "RAM",
            disk: "Disco",
            cpu: "CPU",
            brand: "Marca"
        },
        tag: {
            essential: "Essencial",
            hosting: "Hosting",
            game: "Jogos",
            company: "Empresas",
            all: "Todos"
        },
        regions: "Região do servidor",
        stock: {
            out: "Sem estoque",
            last: "Últimas unidades",
            last_one: "Última unidade"
        },
        configs: {
            title: "Configurações"
        },
        addons: {
            title: "Adicionais"
        },
        faqs: {
            title: "FAQS",
            tabs: {
                traffic: "Tráfego",
                ips: "IPs",
                mitigation: "Mitigação",
                support: "Suporte"
            }
        },
        price: "Preço R$ ",
        buy: "Contratar",
        tables: {
            cpu: "CPU",
            ram: "RAM",
            disk: "Disco",
            trafego: "Tráfego",
            port: "Conexão"
        },
        outStockAll: {
            title: "Sem estoque.",
            subTitle: "Consulte nossos outros produtos."
        }
    },
    ping_test: {
        rtt_medio: "Ping médio ",
        addr_test: "Endereço para teste"
    },
    error: {
        404: "Pagina não encontrada",
        500: "HTTP 500 - Ocorreu um erro do lado do servidor."
    },
    legal: {
        view: {
            terms: "Ver Termos Gerais",
            policy: "Ver politicas"
        },
        title: {
            terms: "Termos Gerais",
            policy: "Politicas"
        },
        description: {
            terms: "Termos de uso e política de privacidade",
            policy: "Termos de uso e política de privacidade"
        }
    },
    blocked: {
        title: "Você está bloqueado",
    }
};