export const NAME = "BB Host";
export const CONFIG_API = "https://cdn.bbhost.com.br/site_json_configs/";

export const STATUSPAGE_API = "https://bbhost.statuspage.io/api/v2/";

export const MAPBOX_KEY = "pk.eyJ1IjoiYmJob3N0IiwiYSI6ImNrcThoYnY0bjAwOHIydm10YjVpa2Ztd2YifQ.aFJJYJ-X4OZXPxTQ4HR7fg";

export const CDN_CONFIG = {
    url: "https://cdn.bbhost.com.br/",
    getUrl: (obj) => {
        if(obj === undefined)
            return CDN_CONFIG.url + "images/capa_fundo_home.png";

        return CDN_CONFIG.url + obj.path;
    },
    loader: (obj) => {
        return CDN_CONFIG.url + obj.src;
    }
}

export const MENU_CONFIG = {
    logo: {
        width: 110,
        height: 70
    }
}

import axios from "axios";

export function getBBHostApiProduct(name){
    return axios.get(`https://api.bbhost.com.br/products/${name}`, { headers: { 
        "User-Agent": "bbhost_site_get/2.0", 
        "Authorization": `Bearer ${process.env.BBHOST_API_TOKEN}` 
    }});
}

export function getBBHostApiConfig(){
    return axios.get(`https://api.bbhost.com.br/site/config`, { headers: { 
        "User-Agent": "bbhost_site_get/2.0", 
        "Authorization": `Bearer ${process.env.BBHOST_API_TOKEN}` 
    }});
}

export function getApiEndPoint(host)
{
    const allowHosts = ['localhost:3000', 'www.bbhost.com.br', 'bbhost.com.br', 'dev.bbhost.com.br', 'dev1.bbhost.com.br'];

    if(allowHosts.includes(host))
    {
        if (process.env.NODE_ENV == "production")
            return `https://${host}`;
        else
            return `http://${host}`;
    }
    else
        return false;
}

export function getDomainByENV()
{
    if(process.env.NEXT_PUBLIC_VERCEL_ENV == "production")
        return "https://www.bbhost.com.br"
    if(process.env.NEXT_PUBLIC_VERCEL_ENV == "preview" || process.env.NEXT_PUBLIC_VERCEL_ENV == "development")
        return "https://dev.bbhost.com.br"
    else if(process.env.NODE_ENV != "production")
        return "http://localhost:3000"

    return "https://www.bbhost.com.br"
}

import {
    IconFlagBR,
    IconFlagUS,
    IconFlagES
} from 'material-ui-flags';

export const countryFlags = {
    IconFlagBR: <IconFlagBR />,
    IconFlagUS: <IconFlagUS />,
    IconFlagES: <IconFlagES />
}

export const localeText = {
    br: {
        text: "BR",
        icon: <IconFlagBR/>
    },
    en: {
        text: "US",
        icon: <IconFlagUS/>
    },
    es: {
        text: "ES",
        icon: <IconFlagES/>
    }
}

import defaultBG from "public/img/bg/capa_fundo_dedicado.png";

export const SEO = {
    openGraph: {
        type: 'website',
        locale: 'pt_BR',
        url: 'https://www.bbhost.com.br',
        site_name: 'BB Host, datacenter de qualidade!',
        title: 'BB Host, datacenter de qualidade!',
        description: 'BB Host, datacenter de qualidade!',
        images: [
            {
              url: `${getDomainByENV()}${defaultBG.src}`,
              width: 800,
              height: 600,
              alt: 'BB Host, datacenter de qualidade!',
            },
        ],
    },
    languageAlternates: [
        {
            hrefLang: 'en',
            href: 'https://www.bbhost.com.br/en',
        },
        {
            hrefLang: 'es',
            href: 'https://www.bbhost.com.br/es',
        }
    ],
    facebook: {
        appId: '1948039421960985',
    },
    twitter: {
        cardType: 'summary_large_image',
    },
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}