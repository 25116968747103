import { useRouter } from 'next/router';
import get from 'lodash/get';

import brasil from "lang/br.js";
import eua from "lang/en.js";
import espanhol from "lang/es.js";

const defaultLocale = "br";

const lang = {
    br: brasil,
    en: eua,
    es: espanhol
};

function getLang(key, local)
{    
    if(local === undefined)
    {         
        const { locale } = useRouter();
        local = locale;
        
        if(lang[local] === undefined)
        {
            local = defaultLocale;
        }
    }

    
    return get(lang[local][key[0]], key[1]);
}

export function getLangFromObj(obj, local)
{
    if(local === undefined)
    {         
        const { locale } = useRouter();
        local = locale;
        
        if(obj[local] === undefined)
        {
            local = defaultLocale;
        }
    }

    return get(obj, local);
}

export function trans(key, local)
{
    key = key.split("::");

    return getLang(key, local);
}